import { DynamicObject, HorizontalView, TView, VerticalView } from '../client/components/layout/View';

export const normalizeObject = <T>(obj: Record<string, T> | undefined): Record<string, NonNullable<T>> => {
  if (obj == null) {
    return {};
  }
  const normalized: Record<string, NonNullable<T>> = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (value != null) {
      normalized[key] = value;
    }
  });

  return normalized;
};

export const formatDuration = (duration: number, format: string): string => {
  const milliseconds = duration % 1000;
  const seconds = Math.floor((duration / 1000) % 60);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  const days = Math.floor(duration / (1000 * 60 * 60 * 24));

  return format
    .replace('HH', hours.toString().padStart(2, '0'))
    .replace('H', hours.toString())
    .replace('mm', minutes.toString().padStart(2, '0'))
    .replace('m', minutes.toString())
    .replace('ss', seconds.toString().padStart(2, '0'))
    .replace('s', seconds.toString())
    .replace('SS', milliseconds.toString().padEnd(3, '0'))
    .replace('S', milliseconds.toString())
    .replace('DD', days.toString());
};

export const isLayoutView = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  view: TView | DynamicObject<any> | undefined
): view is HorizontalView | VerticalView => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const anyView = view as any;
  return anyView == null || anyView.layout === 'row' || anyView.layout === 'column';
};

export const camelize = (str: string) => {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase());
};
