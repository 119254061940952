export const DEFAULTS = {
  SCREENS_INTERVAL: 5,
  SCREENS_BACKGROUND_COLOR: '#293742',

  WIDGET_CLOCK_FORMAT: 'HH:mm:ss.SS',
  WIDGET_CLOCK_INTERVAL: 50,

  WIDGET_TIMER_FORMAT: 'HH:mm:ss.SS',
  WIDGET_TIMER_INTERVAL: 50,
  WIDGET_TIMER_FINISH_MESSAGE: 'Done',

  WIDGET_IFRAME_INTERVAL: 50,

  WIDGET_SLIDESHOW_INTERVAL: 5,
};
